/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PlusIcon } from '@heroicons/react/24/outline';
import { createSelector } from '@reduxjs/toolkit';
import { getDataByCategory } from '../../redux/reducers/data';
import { decor } from '../../elements/tileDecor';
import Tile from './Tile';
import { SpinnerRight } from '../../assets/mdiIcons';
import { setMessage } from '../../redux/actions/message';
import { enabledCategories } from '../../misc/globalSettings';
import Button from '../../elements/Button';

function AddNewTile({ category, addMode, setAddMode, setEditMode }) {
  const signal = useSelector((state) => state.message.signal[`post${category}Account`]);
  const [signalLock, setSignalLock] = useState(false);

  const dispatch = useDispatch();

  // set edit mode to false as soon as the signal is off
  useEffect(() => {
    // set signalLock to true when it is turned on (so that we know it was on before)
    if (signal) setSignalLock(true);
    // if signalLock is on (meaning it was on before) and signal is off (meaning it got switched off), set editMode to false
    if (!signal && signalLock) setEditMode(false);
  }, [signal]);

  async function handleClick() {
    if (enabledCategories.includes(category)) {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      const allowedAccounts = JSON.parse(user.attributes['custom:all_account_slots']);
      console.log('all_account_slots', allowedAccounts);
      const availableAccounts = allowedAccounts.filter((a) => a === null).length;
      if (availableAccounts > 0) setAddMode({ ...addMode, visible: true, category });
      else dispatch(setMessage('noMoreAccountsAllowed'));
    } else {
      // eslint-disable-next-line no-alert
      alert('This category is not enabled yet.');
    }
  }

  // this is the "add new" tile
  return (
    <button
      type="button"
      // eslint-disable-next-line max-len
      className="relative col-span-1 grid columns-1 justify-center items-center w-full max-w-[18rem] h-full border-dashed border-2 border-gray-300 sm:min-w-full rounded-md cursor-pointer hover:bg-gray-100"
      id={`add-new-${category}-button`}
      // on click update the Dashboard addMode state so that the AddAccount component is displayed and the current category is passed as a prop
      onClick={handleClick}
    >
      <div className="align-middle">
        {/* if global loading is true, caller is from the current category, but it is a post transaction, show spinner */}
        {/* it could also be a delete transaction triggered from the Edit Mode, which we only want to show spinner at the deleted tile for */}
        {signal ? <SpinnerRight className="w-8 h-8 text-gray-400 opacity-100 animate-spin" /> : <PlusIcon className="mx-auto h-9 w-9 text-gray-400" />}
      </div>
    </button>
  );
}
AddNewTile.propTypes = {
  addMode: PropTypes.objectOf(PropTypes.any).isRequired,
  setAddMode: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired,
  setEditMode: PropTypes.func.isRequired,
};

export default function DashboardCategory({ category, editMode, setEditMode, addMode, setAddMode, setAccount, isViewer }) {
  const { t } = useTranslation('app');
  const dispatch = useDispatch();

  const [spinner, setSpinner] = useState(!isViewer); // do not show spinner in viewer mode

  const accounts = isViewer ? useSelector((state) => state.viewer.accounts).filter((a) => a.category === category) : useSelector((state) => state.data[category].accounts);
  const accountThatIsLoading = accounts.find((a) => a.status === 'loading'); // this has an accountId which can be used by Tiles AND as a boolean

  const categoryStatus = useSelector((state) => state.data[category].status);
  const isCategoryLoading = categoryStatus === 'loading';

  const rehydrationStatus = useSelector((state) => state.user.rehydrationStatus);

  function handleSync() {
    setSpinner(true);
    dispatch(getDataByCategory(category)).then(() => {
      setSpinner(false);
    });
  }

  // ↓↓ this has been moved to App component
  // get data on intitial render and whenever rehydration is finished
  // useEffect(() => {
  //   if (isViewer) return () => {}; // don't fetch data if in viewer mode

  //   let promise;
  //   if (rehydrationStatus === 'finished') {
  //     promise = dispatch(getDataByCategory(category));
  //   }

  //   return () => {
  //     if (promise) promise.abort(); // abort the request on unmount
  //     // setSpinner(false);
  //   };
  // }, [rehydrationStatus]);

  // synchonise category / accounts status with component (e.g. if getData is triggered from outside)
  useEffect(() => {
    if (isCategoryLoading || accountThatIsLoading) setSpinner(true);
    else setSpinner(false);
  }, [isCategoryLoading, accountThatIsLoading]);

  if (accounts.length === 0 && !spinner && !editMode) {
    return <div />;
  }

  return (
    <section aria-labelledby="category-components">
      <div className="bg-opacity-0 overflow-hidden py-2 md:py-6 md:px-4">
        <h2 className="sr-only" id="quick-links-title">
          {t(`app:dashboard.categories.${category}`)}
        </h2>
        <div className="pb-6 flex justify-between items-center space-x-2">
          <div className="flex gap-2 text-gray-500">
            <h2 className="text-lg sm:text-xl font-bold">{t(`app:dashboard.categories.${category}`)}</h2>
            {/* spinner replaced by the spinner on the sync button */}
            {/* {spinner && <SpinnerRight className="h-5 w-5 mt-1 animate-spin" aria-hidden="true" />} */}
          </div>
          {!editMode && !isViewer && (
            <Button
              onClick={handleSync}
              text="Sync"
              textColor="text-gray-400"
              textColorHover="text-gray-500"
              formatting="bg-gray-50 hover:bg-gray-100 px-2"
              noFrameNoArrow
              size="xl"
              Icon={SpinnerRight}
              spinnerOn={spinner}
            />
          )}
        </div>
        <div className="grid grid-cols-2 xl:grid-cols-3 gap-4 justify-items-center align-middle">
          {/* this version appears in small res screens */}
          {editMode === true ? (
            <div className="sm:hidden w-full h-full col-span-1 max-w-[18rem]">
              <AddNewTile category={category} addMode={addMode} setAddMode={setAddMode} setEditMode={setEditMode} />
            </div>
          ) : null}
          {accounts
            .slice() // copy array
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((account, idx) => (
              <Tile
                key={account.id}
                account={account}
                category={category}
                index={idx}
                color={decor[category].color}
                Icon={decor[category].Icon}
                setAccount={setAccount}
                editMode={editMode}
                isViewer={isViewer}
                accountThatIsLoading={accountThatIsLoading}
              />
            ))}
          {editMode === true ? (
            <div className="hidden sm:block w-full h-full col-span-1 max-w-[18rem] sm:min-w-full sm:min-h-[4.75rem]">
              <AddNewTile category={category} addMode={addMode} setAddMode={setAddMode} setEditMode={setEditMode} />
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
}
DashboardCategory.propTypes = {
  category: PropTypes.oneOf(['deposits', 'stocks', 'realEstate', 'pension', 'objectsOfValue', 'metals', 'unlistedShares', 'loans', 'crypto']).isRequired,
  editMode: PropTypes.bool,
  setEditMode: PropTypes.func.isRequired,
  addMode: PropTypes.objectOf(PropTypes.any).isRequired,
  setAddMode: PropTypes.func.isRequired,
  setAccount: PropTypes.func.isRequired,
  isViewer: PropTypes.bool,
};
DashboardCategory.defaultProps = {
  editMode: false,
  isViewer: false,
};
